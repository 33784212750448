<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555

.container-search
  line-height: 1.7rem
  font-size: 12px
  .link

.container-search-pad
  // padding-top: 53px
  padding-top: 38px

.required
  color: tomato
// .container-modal-backdrop
//   // background-color: rgba(248,249,250,.5)
//   background-color: rgba(0,0,0,.2)
//   position: fixed
//   top: 0px
//   left: 0px
//   width: 100%
//   height: 100%
//   z-index: 2000
// .container-modal
//   position: fixed
//   z-index: 2001
//   top: 3rem
//   width: 760px
//   left: calc(50% - 760px/2)
//   .close
//     font-size: 24px
//   .body
//   .text-description
//     color: darken(#f8f9fa, 50%)
//     font-size: 12px
//     font-weight: 600
.container-table
  font-size: 12px
  // overflow: scroll
  // position: relative
  // height: calc(100% - 300px)
  // height: 300px
  .sticky-header
    position: sticky
    top: -1px
    // left: 0px
    // display: inline-block
    background-color: #fff
    z-index: 1000
    // border-bottom: solid 2px #ccc
    outline: solid 1px #ccc
    border-bottom: 0
    // font-weight: 800
    color: #333 !important
  .link-view-out
    text-decoration: none
    &.router-link-active
      background-color: #f0f0f0
    .link-view
      color: #007bff
      border-bottom: solid 0.05em #007bff
      font-weight: bold
      transition: color linear 100ms, border linear 100ms
    &:hover
      .link-view
        color: #333
        border-color: lighten(#333, 30%)
        text-decoration: none
    &:visited
      .link-view
        color: #555
        border-bottom: solid 0.05em lighten(#555, 30%)
      &:hover
        .link-view
          color: #333
  // .link-view
  //   // text-decoration: underline
  //   color: #007bff
  //   border-bottom: solid 0.05em #007bff
  //   font-weight: bold
  //   transition: color linear 100ms, border linear 100ms
  //   &:hover
  //     color: #333
  //     border-color: lighten(#333, 30%)
  //     text-decoration: none
  //   &:visited
  //     color: #555
  //     border-bottom: solid 0.05em lighten(#555, 30%)
  //     &:hover
  //       color: #333
  tbody
    tr
      td
        color: #555
.link-card
  margin: 0 -15px
  border-bottom: solid 1px #f0f0f0

  padding: 1.2rem 1rem

  // border-right: solid 2px #fff

  &:hover
    background-color: rgba(0,0,0,.03)
    cursor: pointer
    // border-right: solid 2px #ccc
</style>
<template lang="pug">
.container-search-pad
  div.container-search.bg-white.shadow-sm.pl-2
    router-link.link-back(:to='`/property/${property_id}/message/all`')
      span.text-dark(style='font-size: 18px; line-height: 50px;')
        b-icon-chevron-left
        strong(style='padding-left: 5px; font-size: 12px; line-height: 50px; color: #333') 발송내역
    //- router-link.link-hover(:to='`/property/${property_id}/customer/${document_id}`')
    strong.text-secondary.ml-4.mr-2 메시지 답변 내역
    //- a.btn.text-primary(href='#' onclick='alert(`메뉴 설정 모달 띄움. 좌측 대메뉴에 추가`)') 메뉴추가

  //- .bg-white.shadow-sm.mb-2
    .p-3
      .mb-4
        router-link.sub-link.mr-3(:to='`/property/${property_id}/message/all`')
          strong 발송내역
        router-link.sub-link.mr-3(:to='`/property/${property_id}/message/reply`')
          strong 답변도착

      //- h5 메시지 발송내역

  .row.bg-white
    .col-4.border-right
      .link-card.clearfix
        .row
          .col
            strong.d-block
              | Customer Text
              span.text-muted.ml-2 Status
              span.text-muted.ml-2 Phone
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            br
            .mt-2
            span.badge.badge-light.border.mr-1 Relation
            span.badge.badge-light.border.mr-1 Relation
      //- hr(style='margin: 1rem -15px')
      .link-card.clearfix
        .row
          .col
            strong.d-block
              | Customer Text
              span.text-muted.ml-2 Status
              span.text-muted.ml-2 Phone
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            br
            .mt-2
            span.badge.badge-light.border.mr-1 Relation
            span.badge.badge-light.border.mr-1 Relation
      .link-card.clearfix
        .row
          .col
            strong.d-block
              | Customer Text
              span.text-muted.ml-2 Status
              span.text-muted.ml-2 Phone
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            small.text-muted.mr-2 Field: Value
            br
            .mt-2
            span.badge.badge-light.border.mr-1 Relation
            span.badge.badge-light.border.mr-1 Relation

    .col
      .p-2.mt-2
        .row.justify-content-center.align-items-center
          .col-10
            .mt-4
            //- .text-center
            h5.title 메시지 답변 내역
            p 수신자가 메시지 입력폼을 통해 제출한 내용을 확인합니다.
            br

</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'filter_string'],
  components: {

  },
  computed: {

  },
  watch: {
    // '$store.state.documents'() {
    //   this.load()
    // },
    // 'document_id'() {
    //   this.load()
    // },
    'message_id'() {
      this.load()
    },
  },
  data() {
    return {
      done: false,
      // adding: false,
      // document: {},
      rows: [],
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    this.load()
    // if (this.$store.state.documents) {
    //   this.load()
    // }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/messages`, {

        })
        if (r?.data?.message != 'ok') throw new Error('발송내역 가져오기 실패')
        this.rows = r.data.rows.map(e => {
          return e
        })

        this.done = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    row_updated() {
      console.log('reload from row')
      this.load()
    }
  },
}
</script>
